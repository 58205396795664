import * as bootstrap from 'bootstrap';
import * as autosize from "autosize/dist/autosize";

autosize(document.querySelectorAll('textarea'));

document.addEventListener('DOMContentloaded', function () {
  const navLinks = document.querySelectorAll('.nav-item')
  const menuToggle = document.getElementById('navbarCollapse')
  const bsCollapse = new bootstrap.Collapse(menuToggle)
  navLinks.forEach((l) => {
    l.addEventListener('click', () => { bsCollapse.toggle() })
  })

  bsCollapse.hide();
})

